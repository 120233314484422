import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventComponent } from '@modules/events/components/event/event.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  Event,
  createExampleEventForTemplatePreview,
} from '@models/events/event.model';
import { TranslateModule } from '@ngx-translate/core';
import { EventStore } from '@services/stores/event.store';
import { EventService } from '@services/events/event.service';
import { Helpers } from '@utils/helpers';
import { EventTemplateFont } from '@models/design-templates/event-template-font.model';
import { EventTemplate } from '@models/design-templates/event-template.model';
import { MatMenuModule } from '@angular/material/menu';
import { EventTemplateVisual } from '@models/design-templates/event-template-visual.model';
import { environment as env } from '@environments/environment';

@Component({
  selector: 'app-template-preview-overlay-page',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatMenuModule, EventComponent],
  providers: [EventService, EventStore],
  templateUrl: './template-preview-overlay-page.component.html',
  styleUrl: './template-preview-overlay-page.component.scss',
})
export class TemplatePreviewOverlayPageComponent implements OnInit, OnDestroy {
  event?: Event;
  eventTemplate?: EventTemplate;

  isAdminPreview = false;
  isAIMoodBoardPreview = false;

  inProgress = false;

  constructor(
    public dialogRef: MatDialogRef<TemplatePreviewOverlayPageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private eventStore: EventStore,
  ) {
    this.eventTemplate = data.eventTemplate;
    this.isAdminPreview = data.isAdminPreview;
    this.isAIMoodBoardPreview = data.isAIMoodBoardPreview;

    this.event = createExampleEventForTemplatePreview(data.eventTemplate);
    this.eventStore.setEvent(this.event);
  }

  ngOnInit() {
    if (this.font) {
      Helpers.setEventTheme(
        this.font.primaryLogoFontColor,
        this.font.bodyFontColor,
        this.visual?.backgroundColor,
      );
    }
  }

  get font(): EventTemplateFont | undefined {
    return this.event?.eventTemplate?.font;
  }

  get visual(): EventTemplateVisual | undefined {
    return this.event?.eventTemplate?.visual;
  }

  close() {
    this.dialogRef.close();
  }

  useTemplate() {
    this.dialogRef.close({
      useTemplate: true,
    });
  }

  startWithAi() {
    this.dialogRef.close({
      startWithAI: true,
    });
  }

  useMoodBoard() {
    this.inProgress = true;
    this.dialogRef.close({
      useMoodBoard: true,
    });
  }

  share() {
    if (this.eventTemplate) {
      window.navigator.share({
        title: this.eventTemplate.name,
        url: this.eventTemplate.getLink(),
      });
    }
  }

  report() {}

  ngOnDestroy(): void {
    Helpers.resetEventTheme();
  }

  protected env = env;
}
